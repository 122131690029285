import { Button, Dialog, DialogActions, DialogContent, Typography, useTheme } from "@mui/material"
import { FaWhatsapp } from "react-icons/fa6"
import CardImg from "../../../assets/imgs/express/694c40bc-fe94-4f9d-b46c-c4e83dd89010.png"
import { VideoCalango } from "pages/lp/videos"
import { LoadingButton } from "@mui/lab"
import { useState } from "react"
import { saveAs } from "file-saver"
import { IoMdDownload } from "react-icons/io"
import useAuth from "context/auth"

export const IndicationModal = ({
    handleClose,
    open
}: {
    open: boolean,
    handleClose: () => void
}) => {
    const { user } = useAuth()

    const { palette } = useTheme()
    const [isLoading, setIsLoading] = useState(false)

    const handleDownload = async () => {
        setIsLoading(true); // Mostra o loading
        try {
            const response = await fetch("https://s3.us-east-1.amazonaws.com/assets.numerofavorito.com/videos+lp/calango.mp4");
            if (!response.ok) {
                throw new Error("Erro ao baixar o arquivo.");
            }

            const blob = await response.blob();
            saveAs(blob, "numero_favorito.mp4"); // Salva o arquivo localmente

            saveAs(CardImg, "50 mil.png")
        } catch (error) {
            console.error("Erro no download:", error);
        } finally {
            setIsLoading(false); // Remove o loading, independentemente do sucesso ou falha
        }
    }

    const sendInvite = () => {
        const link = `https://numerofavorito.com/convitedasorte?c=${user?.uuid}`

        window.open(`https://wa.me/?text=Eu%20joguei%20e%20ganhei%20no%20NÚMERO%20FAVORITO.%0A%0AClique%20no%20link%20abaixo%20e%20venha%20ganhar%20você%20também!%0A%0A${link}`, '_blank')
    }

    return (
        <Dialog
            open={open}
            fullScreen
            onClose={handleClose}
        >
            <DialogContent
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 2
                }}
            >
                <Typography textAlign={"center"} variant="h2" mb={5}>Clique abaixo e envie o seu convite para seus amigos</Typography>

                <img src={CardImg} style={{
                    width: '95%',
                    maxWidth: '700px'
                }} />

                <VideoCalango />

                <LoadingButton
                    variant="outlined"
                    fullWidth
                    loading={isLoading} // Mostra o spinner no botão enquanto carrega
                    onClick={handleDownload}
                    endIcon={<IoMdDownload />}
                    sx={{
                        width: '95%', maxWidth: '700px',
                    }}
                >
                    {isLoading ? "Baixando material publicitário..." : "Baixar material publicitário"}
                </LoadingButton>
            </DialogContent>
            <DialogActions sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px"
            }}>

                <Button
                    fullWidth
                    onClick={sendInvite}
                    sx={{ p: 2, display: 'flex', gap: 1, mt: 2, borderRadius: 3, width: '95%', maxWidth: '700px', animation: 'pulse 2s infinite' }}
                    color={"success"}
                    variant="contained">
                    <FaWhatsapp size={45} />
                    <Typography variant="h4" color={palette.common.white} textAlign={"center"} textTransform={'uppercase'}>
                        Enviar convite
                    </Typography>
                </Button>
                <Button
                    onClick={handleClose}
                >
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
    )
}