import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { Button, Container, Grid, Typography, useTheme } from '@mui/material';
import IMGLOGO from "assets/imgs/logo2.svg"
import { FaFileContract, FaHome, FaPlay } from 'react-icons/fa';
import { IoMdTrophy } from 'react-icons/io';
import { useState } from 'react';
import Images from 'utils/imgs';
import { useNavigate } from 'react-router-dom';
import useAuth from 'context/auth';
import RoutesPath from 'utils/routesBases';
import { LuLogOut } from "react-icons/lu";
import { AuthService } from '../../service/auth';
import { MdLiveTv } from 'react-icons/md';
import { FaPeopleGroup } from 'react-icons/fa6';
import { VscGraph } from "react-icons/vsc";
import { Roles } from 'utils';

const LOCATIONS = [
    { id: 0, link: '/venda', name: 'início', Icon: FaHome },
    // { id: 1, link: '/venda/convite-revendedor', name: 'Indicação de revendedores', Icon: null, img: Images.icons.indicacao, size: 40 },
    // {id: 11, link: '/venda/estatistica', name: 'Estatística', Icon: VscGraph},
    // { id: 2, link: '/venda/resultados', name: 'Resultados', Icon: IoMdTrophy },
    // { id: 3, link: '/venda/minhas-comissoes', name: 'VENDAS E COMISSÕES', Icon: null, img: Images.icons.indicacao2, size: 40 },
    { id: 4, link: '/venda/tabela-comissoes', name: 'TABELA DE COMISSÕES', Icon: null, img: Images.icons.comissions },
    { id: 10, link: '/venda/meus-clientes', name: 'MEUS CLIENTES', Icon: FaPeopleGroup },
    // { id: 5, link: '/venda/meus-concursos', name: 'Meus concursos', Icon: null, img: Images.icons.concursos },
    {
        id: 8, link: '/venda/publicidade', name: 'MATERIAL PUBLICITÁRIO', Icon: null, img: Images.icons.publi, style: {
            transform: 'rotateY(180deg)'
        }
    },
    { id: 6, link: '/regulamento', name: 'Regulamento', Icon: FaFileContract },
    // { id: 7, link: '/venda/aprenda', name: 'Videos explicativos', Icon: FaPlay },
    // { id: 9, link: '/sorteios', name: 'Assistir sorteios', Icon: MdLiveTv }




]

export default function SwipeableTemporaryDrawer({ open, handleClose }: { open: boolean, handleClose: () => void }) {

    const theme = useTheme();

    const navigate = useNavigate();

    const { user } = useAuth()

    const [currentPage, setCurrentPage] = useState<number | null>(null)

    return (
        <SwipeableDrawer
            anchor={'right'}
            open={open}
            onClose={handleClose}
            onOpen={() => { }}
            PaperProps={{
                sx: { height: '100vh', width: '100%', maxWidth: 300 }
            }}
        >
            <Container maxWidth="xs" sx={{ p: 0 }}>
                <Grid container bgcolor="white" height='100%' maxWidth={300} alignItems="center" justifyContent={"center"} sx={{ overflowY: 'auto' }}>
                    <Grid p={4}>
                        <img src={IMGLOGO} width="100%" />
                    </Grid>
                    <Grid container mt={4} mb={10}>
                        {
                            LOCATIONS.map(({ id, Icon, name, img, link, style }) => {
                                const colorBG = currentPage === id ? theme.palette.grey[300] : 'transparent';
                                const color = currentPage === id ? theme.palette.primary.main : "#878787";
                                if (user?.role === Roles.general) {
                                    link = link.replace("correspondente", "geral").replace("venda", "geral")
                                }
                                return (
                                    <Grid
                                        key={id}
                                        sx={{ backgroundColor: colorBG, ':focus': { backgroundColor: colorBG } }}
                                        borderBottom={`2px solid ${theme.palette.grey[300]}`}
                                        justifyContent={'flex-start'}
                                        borderRadius={0}
                                        p={3}
                                        container
                                        flexWrap={'nowrap'}
                                        gap={2}
                                        alignItems="center"
                                        component={Button}
                                        onClick={() => {
                                            if (link === 'resultados') {
                                                return
                                            }
                                            setCurrentPage(id);
                                            navigate(link, {
                                                state: {
                                                    prev_path: window.location.pathname
                                                }
                                            })
                                            handleClose();
                                        }}
                                    >
                                        {Icon && <Icon size={30} color={color} />}
                                        {img && <img
                                            src={img}
                                            // height={size ?? 30}
                                            height={30}
                                            className='icon-img_active'
                                            style={{
                                                ...style ?? {},
                                                // marginLeft: size ? -10 : 0,
                                                marginLeft: 0,
                                                color: 'red'
                                            }} />}
                                        <Typography textAlign="start" fontWeight="bold" textTransform="uppercase" color={color} maxWidth={200}>
                                            {name}
                                        </Typography>
                                    </Grid>
                                )
                            })
                        }
                        <Grid
                            borderBottom={`2px solid ${theme.palette.grey[300]}`}
                            justifyContent={'flex-start'}
                            borderRadius={0}
                            p={3}
                            container
                            flexWrap={'nowrap'}
                            gap={2}
                            alignItems="center"
                            component={Button}
                            onClick={AuthService.logout}

                        >
                            <LuLogOut size={30} color={"#878787"} />
                            <Typography textAlign="start" fontWeight="bold" textTransform="uppercase" color={"#878787"} maxWidth={200}>
                                Sair
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </SwipeableDrawer>
    );
}


export const ClientMenu = ({ open, handleClose, purchase }: { open: boolean, handleClose: () => void, purchase?: 'client' | 'saller' }) => {

    const theme = useTheme();

    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState<number | null>(null)

    const { user } = useAuth();

    return (
        <SwipeableDrawer
            anchor={'right'}
            open={open}
            onClose={handleClose}
            onOpen={() => { }}
            PaperProps={{
                sx: { height: '100vh', width: '100%', maxWidth: 300 }
            }}
        >
            <Container maxWidth="xs" sx={{ p: 0 }}>
                <Grid container bgcolor="white" height='100%' maxWidth={300} alignItems="center" justifyContent={"center"}>
                    <Grid p={4}>
                        <img src={IMGLOGO} width="100%" />
                    </Grid>
                    <Grid container mt={4}>
                        <Grid
                            borderBottom={`2px solid ${theme.palette.grey[300]}`}
                            justifyContent={'flex-start'}
                            borderRadius={0}
                            p={3}
                            container
                            flexWrap={'nowrap'}
                            gap={2}
                            alignItems="center"
                            component={Button}
                            // color={color}
                            onClick={() => {
                                if (purchase === 'client') {
                                    navigate(user ? RoutesPath.Clients.path : RoutesPath.Clients.login.path + window.location.search)
                                } else {
                                    navigate('/venda')
                                }
                                handleClose();
                            }}
                        >
                            <Typography fontWeight={"bold"} color="black">
                                {user ? "Inicio" : "Login"}
                            </Typography>
                        </Grid>

                    </Grid>
                </Grid>
            </Container>
        </SwipeableDrawer>
    );
}