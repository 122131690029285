import { LPNav } from '../../../layout/lp';

import style from './styles.module.css'
import { useMediaQuery, } from '@mui/material';
import { RodapeLP } from 'layout/lp/rodape';
import WomanWithCellphone from 'assets/imgs/lp/contest/ATRIZ 1 1.png'
import Woman from 'assets/imgs/lp/contest/ATRIZ 2 1.png'
import Arrow from 'assets/imgs/lp/contest/arrow.png'
import Peoples from 'assets/imgs/lp/contest/peoples.png'
import IntagramLogo from 'assets/imgs/lp/contest/LOGO INSTAGRAM 1.png'
import WhatsAppLogo from 'assets/imgs/lp/contest/WHATSAPP.png'
import { useEffect, useState } from 'react';
import useQuery from '../../../hooks/useQuery';
import { useNavigate } from 'react-router-dom';
import { constants, isMobile } from '../../../utils';
import ThumbMobile from '../../../assets/imgs/lp/whoWeAre/COMO APOSTAR 02.png'
import ThumbDesktop from '../../../assets/imgs/lp/whoWeAre/COMO APOSTAR 01.png'
import { AuthService } from '../../../service/auth';
import StorageService from '../../../service/storage';
import UserProps from '../../../types/user';
import { FaPlay } from 'react-icons/fa6';
import BannerNewPrizes from '../../../assets/imgs/lp/whoWeAre/PRÊMIO DA QUADRA SITE.png'
import { VideoCalango } from '../videos';

export function LPContest() {
    const [indication, setIndication] = useState<boolean | null>(true)
    const query = useQuery()
    const [client, setClient] = useState(query.get('c'))
    const [correspondent, setCorrespondent] = useState(!query.get('s') || query.get('s') === "" ? constants.homeUser : query.get('s'))
    const navigate = useNavigate();
    const media = useMediaQuery('(min-width:1250px)')

    const [clientData, setClientData] = useState<UserProps>()

    const onClick = () => {
        let query = "?"
        if (client) {
            query += `c=${client}&`
        }
        if (correspondent) {
            if (correspondent !== constants.homeUser) {
                query += `s=${correspondent}&`
            } else if (clientData?.lastSellerId) {
                query += `s=${clientData?.lastSellerId}&`
            } else {
                query += `s=${correspondent}&`
            }
        }
        navigate("/cliente/concurso" + query)
    }

    useEffect(() => {
        if (StorageService.getTokeClient()) {
            AuthService.clientMe().then(({ data }) => {
                setClientData(data)
            })
        }
    }, [])


    return (
        <div className="containerLP">
            <LPNav />
            <div className={style.firstContainer} onClick={onClick}>
                <div className={style.left}>
                    {!media && <>
                        <p className={style.dinamicText} style={{ marginTop: '50px' }}>Sorteios instantâneos! {media && <br />}
                            24 horas por dia, 7 dias por semana.</p>
                        {indication && <div className='divStamp'>
                            <button onClick={onClick} className="buttonNow" style={{ margin: '15px 0px' }}>Quero apostar agora!</button>
                        </div>}
                    </>}
                    <img src={WomanWithCellphone} alt="woman with cellphone" />
                    {/* <div>
                        {media && indication &&

                            <div className='divStamp'>
                                <button onClick={onClick} className="buttonNow" style={{ margin: '25px 0px' }}>Quero apostar agora!</button>
                            </div>}
                        <p>Assista nosso vídeo e conheça mais o <span>NÚMERO FAVORITO</span></p>
                        <video controls controlsList="nodownload" preload="auto" playsInline poster={isMobile() ? ThumbMobile : ThumbDesktop}>
                            <source src={isMobile() ? "https://s3.amazonaws.com/static.numerofavorito.com/videos+lp/mobile.mp4" : "https://s3.amazonaws.com/static.numerofavorito.com/videos+lp/desktop.mp4"} type="video/mp4" />
                        </video>
                    </div> */}
                </div>
                <div className={style.right}>
                    {media && <p className={style.dinamicText}>Sorteios instantâneos!<br />
                        24 horas por dia, 7 dias por semana.</p>}
                    {media && indication &&
                        <div className='divStamp'>
                            <button onClick={onClick} className="buttonNow" style={{ marginTop: '15px' }}>Aposte agora!</button>
                        </div>}

                    {/* <div className={style.containerVideoHowTo}>
                        <p>
                            <FaPlay size={30} />
                            Aprenda a apostar</p>
                        <video controls controlsList="nodownload" preload="auto" playsInline poster={isMobile() ? ThumbMobile : ThumbDesktop}>
                            <source src={"https://s3.amazonaws.com/assets.numerofavorito.com/videos+lp/COMO+APOSTAR+2.mp4"} type="video/mp4" />
                        </video>
                    </div> */}

                    <div className={style.containerNewPrizes}>
                        <img src={BannerNewPrizes}></img>
                    </div>
                    <VideoCalango />
                    <p className={style.p}>Aqui você <span className={`${style.giant} ${style.win}`}>ganha</span> acertando</p>
                    <div className={style.containerArrows}>
                        <div>
                            <img src={Arrow} alt='arrow' />
                            <p>4 números</p>
                            <div>
                                QUADRA
                            </div>
                        </div>
                        <div>
                            <img src={Arrow} alt='arrow' />
                            <p>3 números</p>
                            <div>
                                TERNO
                            </div>
                        </div>
                        <div>
                            <img src={Arrow} alt='arrow' />
                            <p>2 números</p>
                            <div>
                                DUQUE
                            </div>
                        </div>
                        <div>
                            <img src={Arrow} alt='arrow' />
                            <p>1 número</p>
                            <div>
                                UNIDADE
                            </div>
                        </div>
                    </div>
                    <p className={style.typography}>SORTE EXTRA! Aproximou, Ganhou.<span><br />É muito, muito, muito, muito</span> fácil de ganhar na hora até <span>50.000 vezes</span> o valor da aposta.</p>
                    <div className={style.containerLastWoman}>
                        {indication && !media &&
                            <div className='divStamp'>
                                <button onClick={onClick} className="buttonNow">Quero apostar agora!</button>
                            </div>}
                        <img src={Woman} alt="mulher cruzando os dedos" />
                        <p>Seja <span className={style.giant}>favorito</span> e tenha muitas chances de <span className={style.giant}>ganhar!</span></p>
                    </div>
                </div>
            </div>
            <div className={style.lastContainer}>
                <div className={style.social}>
                    <div>
                        <div><a href="https://www.instagram.com/numerofavorito/" target='_blank'><img src={IntagramLogo} alt="logo do instagram" /></a></div>
                        <p>Siga nosso <a href="https://www.instagram.com/numerofavorito/" target='_blank'>Instagram</a></p>
                    </div>
                    {/* <div>
                        <div><a href="https://chat.whatsapp.com/JgXQM7gnO6tJBcXFG64gbo" target='_blank'><img src={WhatsAppLogo} alt="logo do whatsapp" /></a></div>
                        <p>Fique por dentro das novidades em nosso grupo do <a href="https://chat.whatsapp.com/JgXQM7gnO6tJBcXFG64gbo" target='_blank'>WhatsApp</a></p>
                    </div> */}
                </div>
                <div className={style.peoples}>
                    {indication &&
                        <div className='divStamp'>
                            <button onClick={onClick} className="buttonNow" style={{ margin: '25px 0px', ...(!media && { marginTop: -10 }) }}>Clique aqui para apostar agora!</button>
                        </div>}
                    <img src={Peoples} alt="pessoas comemorando" />
                </div>
            </div>
            <RodapeLP />
        </div>
    )
}